<template>
  <div class="w-100 d-flex-inline align-items-between">
    <div>
      
    </div>
    <div class="float-right">
      <b-button variant="primary">
        <h3 class="text-light">
          Take Next Issue In Queue
        </h3>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    issuesQueue: { type: Array, default: () => [] },
    issuesQueueMeta: { type: Object, default: () => {} },
  },
}
</script>

<style>

</style>
