<template>
  <div class="h-100">
    <b-card class="h-100">
      <b-row style="min-height: 70vh;">
        <b-col
          class="d-flex flex-wrap align-items-center text-center"
          cols="2"
        >
          <div class="w-100">
            List of Queued issue (unclickable)
          </div>
        </b-col>

        <b-col class="">
          <div class="d-flex h-25 w-100">
            <div class="w-25 border border-primary">
              Todays total issue 
            </div>
            <div class="w-25 border border-primary">
              Total Closed Issue
            </div>
            <div class="w-25 border border-primary">
              Total Open Issue
            </div>
            <div class="">
              <next-in-queue :issues-queues="issuesQueues" :issues-queues-meta="issuesQueuesMeta"/>
            </div>
          </div>

          <div class="d-flex h-75 w-100 border border-warning">
            List of Current Agent Taken Issue
          </div>
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
import NextInQueue from '../components/NextInQueue.vue'
import NextQueueNumber from '../components/NextQueueNumber.vue'

export default {
  components: {
    NextInQueue,
    NextQueueNumber,
  },
  data() {
    return {
      issuesQueues: [],
      issuesQueuesMeta: [],
    }
  },
  created() {
    this.get_current_issues_queues()
  },
  methods: {
    get_current_issues_queues() {
      this.$http
        .get('/api/help-desks/issues-queues-current')
        .then(response => {
          this.issuesQueues = response.data.output
          this.issuesQueuesMeta = response.data.meta
        })
        .catch(() => {})
    } 
  },
}
</script>

<style>

</style>